import React, {createRef, useEffect} from "react"
import { graphql, navigate } from "gatsby"
// import QRCode from "qrcode"

import Layout from "../components/layout"
import NavBar from "../components/nav_bar"
import { Pagination } from "@material-ui/lab"
import QrCoderdr from '../components/qr_reader'
import SEO from "../components/seo"

import {
  Select,
  FormControl,
  MenuItem,
  Container,
  makeStyles,
  Grid,
  CssBaseline,
  Fade,
  LinearProgress,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@material-ui/core"

import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"

import Alert from '@material-ui/lab/Alert'
import GetAppIcon from '@material-ui/icons/GetApp'
import OfflinePinIcon from '@material-ui/icons/OfflinePin'

const windowGlobal = typeof window !== 'undefined' && window

const useStyles = makeStyles(theme => ({
  tblPgntn: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%)",
    bottom: "36%",
    zIndex: 9,
    textAlign: "center",
    width: "100%",
    "& .MuiPagination-ul": {
      display: "inline-flex",
    },
    '& .MuiPagination-ul .MuiPaginationItem-root': {
      color: "#fff",
    },
  },
  formControl: {
    marginBottom: 12,
    display: "inline-flex",
    marginRight: 0,
    marginLeft: 0,
    '& .MuiInputBase-input': {
      backgroundColor: '#005979',
      fontSize: 18,
      color: '#fff',
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
      lineHeight: "2",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    display: "inherit",
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0',
    },
    '& .MuiSelect-iconOutlined': {
      color: "#fff",
    },
  },
  root: {
    width: "100%",
  },
  svgImg: {
    marginRight: 15,
    width: 40,
    height: "auto",
    maxHeight: 25,
    marginBottom: 0,
    marginTop: 6,
    float: "left",
    '& img': {
      borderRadius: 5,
      maxWidth: 40,
      height: 25,
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroButtons: {
    marginTop: 12,
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  langName: {
    textTransform: "capitalize",
    verticalAlign: "middle",
  },
  langNameReader: {
    marginLeft: "25%"
  },
  readerLabelNoRtl: {
    position: "relative",
    backgroundColor: '#005979',
    color: "#fff",
    '& p': {
      marginTop: 15,
      marginBottom: 15,
      marginLeft: 80,
      fontSize: 18,
    },
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 10,
  },
  readerImg: {
    '& img': {
      width: 65,
      height: 65,
      borderRadius: "100%",
      border: "2px solid #01526f",
      display: "inline-block",
      position: "absolute",
      top: "-5px",
    },
  },
  readerImgRtl: {
    '& img': {
      top: "-6px",
      width: 63,
      height: 63,
    }
  },
  svgImgRtl: {
    marginRight: "initial",
    marginLeft: 15,
  },
  NameReaderRtl: {
    '& span': {
      marginRight: "14%"
    }
  },
  Readeroptions: {
    overflow: "visible",
    paddingTop: 16,
    paddingBottom: 16,
    '& div img': {
      width: 43,
      height: 43,
      top: "50%",
      position: "absolute",
      transform: "translate(0, -50%)",
    },
    langNameReaderRtl: {
      marginLeft: "initial",
      marginRight: 60
    },
    langNameReaderltr: {
      '&.langName': {
        marginLeft: '60px !important',
        marginRight: "initial !important"
      },
    },
  },
  readerTxt: {
    marginTop: 0,
    '& span': {
      marginLeft: "initial",
      marginRight: 95
    },
  },
  langNameReaderltr: {
    marginLeft: '95px !important',
    marginRight: "initial !important"
  },
  readerFormControl: {
    margin: '0 0 16px 0',
    // display: "none",
    // '& .MuiInputBase-input': {
    //   paddingTop: "17px !important",
    //   paddingBottom: "17px !important",
    // },
  },
  select: {
    '& .MuiInputBase-formControl .MuiInputBase-input': {
      backgroundColor: '#005979',
      color: '#fff',
    },
  },
  svgContainer: {
    width: 75,
    height: 75
  },
  svgContainerParent: {
    margin: "0 auto",
    height: "auto",
    textAlign: "center",
    width: 126,
    position: "absolute",
    zIndex: 2,
    left: "50%",
    bottom: 110,
    transform: "translate(-50%)",
    [theme.breakpoints.up('sm')]: {
      bottom: "57.3%",
    },
  },
  langNameLabel: {
    marginTop: 10,
    fontSize: 14,
    color: "black",
    marginLeft: 30,
    marginRight: 30,
  },
  selectLang: {
    // marginTop: 16,
    // position: "absolute",
    width: "100%"
  },
  formControlReader: {
    marginTop: 16,
    marginBottom: 0,
    position: "relative"
  },
  pageContainer: {
    minHeight: 318,
    position: "relative",
    // paddingBottom: 190,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 90,
    },
  },
  ftrmain: {
    backgroundImage: "radial-gradient(circle farthest-side,#00b1d5 2%, #005b7b 70%)",
    backgroundSize: "contain",
    paddingTop: "10em",
    position: "relative",
    paddingBottom: "2em",
    marginTop: 74,
    width: "100%",
    left: 0,
    [theme.breakpoints.up('sm')]: {
      bottom: 0,
    },
    [theme.breakpoints.up('md')]: {
      position: "absolute",
    },
  },
  ftrdiv: {
    textAlign: "center",
  },
  ftrcpyright: {
    color: "#fff",
  },
  ftrSvg: {
    fill: "#fff"
  },
  button: {
    position: "absolute",
    top: 48,
    left: "17%",
    color: "#868686",
  },
  audioWrap: {
    position: 'relative'
  },
  radio: {
    '&.Mui-checked': {
      color:   '#005979'
    }
  },
  radioOption: {
    textAlign: 'center'
  }
}))

var played = false

const PageTemplate = ({ data }) => {

  const urlSegment = "/p/"
  // const pageUrl =
  //   data.site.siteMetadata.quranSiteUrl +
  //   urlSegment +
  //   data.strapiPages.page_number
  // const [qrImageData, setQrImageData] = React.useState("")
  // let qrHtmlSvg = { __html: qrImageData }

  // QRCode.toString(pageUrl, {
  //   type: "svg",
  //   errorCorrectionLevel: "L",
  // })
  //   .then(url => {
  //     setQrImageData(url)
  //   })
  //   .catch(err => {
  //     console.error(err)
  //   })

  const handlePageChange = (event, value) => {
    navigate(urlSegment + value)
  }

  const classes = useStyles()

  // let lclLang = ''
  // if (windowGlobal) {
  //   if (windowGlobal.localStorage.getItem('language')) {
  //     lclLang = windowGlobal.localStorage.getItem('language')
  //   } else {
  //     lclLang = navigator.language.substring(0, 2)
  //     windowGlobal.localStorage.setItem('language', lclLang)
  //   }
  // }

  const langs = {}
  for (const value of data.allStrapiLangs.nodes) {
    langs[value.lang] = value
  }

  // const [lang, setLang] = React.useState(lclLang)
  const [loading, setLoading] = React.useState(false)
  const [downloadStatus, setDownloadStatus] = React.useState(0)
  const [errorPlayy, setErrorPlayy] = React.useState('')

  const fullData = {}
  const dataNew = {}
  for (const value of data.strapiPages.audio_data) {
    dataNew[value.lang] = { ...value, lang: langs[value.lang] }
    if (!fullData[value.lang]) {
      fullData[value.lang] = {}
    }
    fullData[value.lang][value.reader] = { ...value, lang: langs[value.lang] }
  }
  let lclLang = ''

  if (windowGlobal) {
    if (windowGlobal.localStorage.getItem('language')) {
      lclLang = windowGlobal.localStorage.getItem('language')
    } else {
      lclLang = navigator.language.substring(0, 2)
      if ( dataNew[lclLang] !== undefined ) {
        windowGlobal.localStorage.setItem('language', lclLang)
      } else {
        lclLang = 'ar'
        windowGlobal.localStorage.setItem('language', lclLang)
      }
      
    }
  }
  const [lang, setLang] = React.useState(lclLang)

  let lclReader = ''
  if (windowGlobal) {
    if (windowGlobal.localStorage.getItem('reader')) {
      lclReader = windowGlobal.localStorage.getItem('reader')
    } else {
      // if (lclLang !== 'ar') {
      //   lclReader = dataNew[lclLang] ? dataNew[lclLang].reader : ''
      // } else {
        lclReader = 'العفاسي'
      // }
      windowGlobal.localStorage.setItem('reader', lclReader)
    }
  }

  const [reader, setReader] = React.useState(lclReader)

  const handleReaderChange = event => {
    setReader(event.target.value)
    windowGlobal.localStorage.setItem('reader', event.target.value)
    setTrans('arabic')
  }

  let lclTrans = ''
  if (windowGlobal) {
    if (windowGlobal.localStorage.getItem('lastTrans')) {
      lclTrans = windowGlobal.localStorage.getItem('lastTrans')
    } else {
        lclTrans = 'arabic'
      windowGlobal.localStorage.setItem('lastTrans', lclTrans)
    }
  }

  const [trans, setTrans] = React.useState(lclTrans)

  const handleTransChange = (event) => {
    setTrans(event.target.value)
  }

  const locale = {}
  for (const value of data.allStrapiLocale.nodes) {
    let textObj = {}
    for (const value2 of value.text) {
      textObj[value2.lang.lang] = value2.text
    }
    locale[value.original] = textObj
  }

  const showAudioLoader = () => {
    setLoading(true)
    setTimeout(
      function () {
        setLoading(false)
      }
      , 500
    );
  }

  const changeLang = (lang) => {
    setLang(lang)
    showAudioLoader()
    // let lcoReader = ''
    // if (lang !== 'ar') {
    //   lcoReader = dataNew[lang] ? dataNew[lang].reader : ''
    // } else {
    //   lcoReader = 'العفاسي'
    // }

    // setReader(lcoReader)
    windowGlobal.localStorage.setItem('language', lang)
    // windowGlobal.localStorage.setItem('reader', lcoReader)
  }

  const handleLangChange = event => {
    changeLang(event.target.value)
  }

  const handleErrorPlay = event => {
    setErrorPlayy('true')
  }

  const downloadThisPage = (value, num) => {

    setDownloadStatus(1)
    caches.open('gatsby-plugin-offline-runtime').then(function (cache) {
      fetch(value)
        .then((response) => {
          if (!response.ok) {
            throw new TypeError('bad response status');
          }
          return cache.put(value, response)
        })
        .then((data) => {
          setTimeout(() => {
            windowGlobal.localStorage.setItem('page-' + lang + '-' + num, 'downloaded')
            setDownloadStatus(0)
          }, 2000);
        })

    })
  }

  let dir = 'ltr'
  if (lang) {
    dir = lang === "ar" ? "rtl" : "ltr"
  } else {
    dir = lclLang === "ar" ? "rtl" : "ltr"
  }

  const player = createRef();

  if (windowGlobal) {
    windowGlobal.onunload = function(){
      windowGlobal.localStorage.setItem('lastUrl', '/p/' + data.strapiPages.page_number)
      windowGlobal.localStorage.setItem('lastTime', player.current.audio.currentTime )    
      windowGlobal.localStorage.setItem('lastTrans', trans )    
    }
  }

  const direction = dir === "rtl" ? "rtl" : "ltr"
  const readerImgRtl = dir === "rtl" ? "readerImgRtl" : "readerImgLtr"
  const langNameReader = dir === "rtl" ? "langNameReaderRtl" : "langNameReaderltr"
  const NameReader = dir === "rtl" ? "NameReaderRtl" : "NameReaderltr"
  const svgImg = dir === "rtl" ? "svgImgRtl" : "svgImgltr"
  const readerFormControl = dir === "rtl" ? "readerFormControlRtl" : "readerFormControlLtr"

  const goToTime = () => {
    if (windowGlobal && !played) {
      const lastTime = windowGlobal.localStorage.getItem('lastTime')
      const lastUrl = windowGlobal.localStorage.getItem('lastUrl')
      if (lastTime && '/p/' + data.strapiPages.page_number === lastUrl) {
        player.current.audio.currentTime = lastTime
        played = true
      }
    }
  }

  const finalReader = trans !== 'arabic' ? 'reader' : reader
  const finalLang = trans !== 'arabic' ? lang : 'ar'
 
  return (
    <Layout
      lang={lang}
      onLangChange={changeLang}
      locale={locale}
      dataNew={dataNew}
      siteUrl={data.site.siteMetadata.siteUrl}
      className={classes.layoutContainer}
    >
      <SEO title={locale['Page'][lang]} lang={lang} />
      <CssBaseline />
      <NavBar
        locale={locale}
        lang={lang}
        pageNumber={data.strapiPages.page_number}
        partNumber={data.strapiPages.part ? data.strapiPages.part.part_number : 1}
        surahNumber={data.strapiPages.surah ? data.strapiPages.surah.surah_number : 2}
      />
      <Container maxWidth="sm" className={classes.pageContainer}>
        <div className={`${classes[direction]} ${classes.heroButtons}`}>
          <Grid
            justify="center"
            alignContent="center"
            alignItems="center"
            container
            style={{
              marginTop: 7,
            }}
          >
            <Grid md={12} xs={12} item >
              <FormControl
                required
                variant="outlined"
                className={`${classes.formControl} ${classes.readerFormControl} ${classes[readerFormControl]} readerFormControl dir`}
                style={{ width: "100%" }}
              >
                {fullData['ar'] &&
                  <Select
                    className={`${classes.selectEmpty} ${classes.readerTxt} ${classes[direction]}`}
                    value={reader}
                    name="Readers"
                    onChange={handleReaderChange}
                  >
                    {fullData['ar'] && Object.keys(fullData['ar']).map(key => (
                      <MenuItem value={key} key={key} className={`${classes.Readeroptions} Readeroptions dir`}>
                        <div className={`${classes.readerImg} ${classes[readerImgRtl]}`}>
                          <img
                            src={data.allStrapiReaders.nodes.filter(item => item.reader === key)[0].main_image.publicURL}
                            alt={key}
                          />
                        </div>
                        <span className={`${classes.langName} ${classes[langNameReader]} ${classes[NameReader]} langName`}> {data.allStrapiReaders.nodes.filter(item => item.reader === key)[0].reader_name} </span>
                      </MenuItem>
                    ))}
                  </Select>
                }
              </FormControl>
            </Grid>
            <Grid md={12} xs={12} item>
              {dataNew[lang] &&
                <div className={classes.audioWrap}>
                  {fullData[finalLang][finalReader] ?
                    <AudioPlayer
                      src={'/content/pages/' + finalLang + '/' + finalReader + '/' + fullData[finalLang][finalReader].file}
                      rounded={true}
                      autoPlay
                      preload="metadata"
                      ref={player}
                      onError={handleErrorPlay}
                      onCanPlay={goToTime}
                    />
                    :
                    <p>File Not Found for this reader or in this lang !</p>
                  }
                  {windowGlobal && fullData[finalLang][finalReader] && windowGlobal.localStorage.getItem('page-' + finalLang + '-' + data.strapiPages.page_number) === 'downloaded' ? <OfflinePinIcon className={classes.button} color="primary" /> :
                    <IconButton
                      onClick={(e) => downloadThisPage('/content/pages/' + finalLang + '/' + finalReader + '/' + data.strapiPages.page_number + '.mp3', data.strapiPages.page_number)}
                      size="small"
                      className={classes.button}
                    >
                      {downloadStatus === 1 && <CircularProgress size={14} />}
                      {downloadStatus === 0 && <GetAppIcon size={14} />}
                    </IconButton>
                  }
                  <Fade in={loading} unmountOnExit>
                    <LinearProgress />
                  </Fade>
                  {errorPlayy === 'true' &&
                    <Alert className="dir" severity="error">{locale['audio-file-alert'][lang]}</Alert>
                  }
                </div>
              }
            </Grid>
            <Grid md={1} xs={1} item />
            <Grid md={10} xs={10} item>
              <FormControl
                required
                variant="outlined"
                className={`${classes.formControl} ${classes.formControlReader}`}
                style={{ width: "100%" }}
              >
                <RadioGroup row aria-label="translations" name="translations1" value={trans} onChange={handleTransChange}> 
                  <Grid item xs={false} sm={2}></Grid>
                  <Grid item xs={6} sm={4} className={classes.radioOption}>
                    <FormControlLabel value="translation" control={<Radio className={classes.radio} />} label={locale['translation'][lang]} labelPlacement="start" />
                  </Grid>
                  <Grid item xs={6} sm={4} className={classes.radioOption}>
                    <FormControlLabel value="arabic" control={<Radio className={classes.radio} />} label={locale['arabic'][lang]} labelPlacement="start" />
                  </Grid>
                  <Grid item xs={false} sm={2}></Grid>
                </RadioGroup>
                {Object.entries(dataNew).length !== 0 &&
                  <Select
                    className={`${classes.selectEmpty} ${classes.selectLang} dir`}
                    value={lang}
                    name="Languages"
                    onChange={handleLangChange}
                  >
                    {Object.keys(dataNew).map(key => (
                      <MenuItem value={key} key={key} className='dir'>
                        <div className={`${classes.svgImg} ${classes[svgImg]}`}>
                          <img
                            src={
                              dataNew[key].lang.flag.publicURL
                            }
                            alt={key}
                          />
                        </div>{" "}
                        <span className={classes.langName}> {dataNew[key].lang.lang_name} </span>
                      </MenuItem>
                    ))}
                  </Select>
                }
                {Object.entries(dataNew).length === 0 &&
                  <p>No Data here yet.</p>
                }

              </FormControl>
            </Grid>
            <Grid md={1} xs={1} item />
          </Grid>
        </div>
      </Container>
      <footer className={`${classes.ftrmain} ftrmain`}>
        <div className={classes.svgContainerParent}>
          <QrCoderdr />
        </div>
        <Pagination
          className={classes.tblPgntn}
          count={604}
          page={parseInt(data.strapiPages.page_number, 10)}
          onChange={handlePageChange}
        />
        <Box className={classes.ftrdivmain}>
          <Box className={classes.ftrdiv}>
            <Typography
              className={classes.ftrcpyright}
              component="span"
              variant="subtitle2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Tecvitli kuran. All Rights Reserved <svg className={classes.ftrSvg} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" style={{ verticalAlign: "middle" }}><path d="M10.08 10.86c.05-.33.16-.62.3-.87s.34-.46.59-.62c.24-.15.54-.22.91-.23.23.01.44.05.63.13.2.09.38.21.52.36s.25.33.34.53.13.42.14.64h1.79c-.02-.47-.11-.9-.28-1.29s-.4-.73-.7-1.01-.66-.5-1.08-.66-.88-.23-1.39-.23c-.65 0-1.22.11-1.7.34s-.88.53-1.2.92-.56.84-.71 1.36S8 11.29 8 11.87v.27c0 .58.08 1.12.23 1.64s.39.97.71 1.35.72.69 1.2.91 1.05.34 1.7.34c.47 0 .91-.08 1.32-.23s.77-.36 1.08-.63.56-.58.74-.94.29-.74.3-1.15h-1.79c-.01.21-.06.4-.15.58s-.21.33-.36.46-.32.23-.52.3c-.19.07-.39.09-.6.1-.36-.01-.66-.08-.89-.23-.25-.16-.45-.37-.59-.62s-.25-.55-.3-.88-.08-.67-.08-1v-.27c0-.35.03-.68.08-1.01zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg> {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </footer>
    </Layout>
  )
}
export default PageTemplate

export const query = graphql`
  query PageTemplate($id: String!) {
    strapiPages(id: { eq: $id }) {
      page_number
      audio_data {
        lang
        reader
        file
      }
      part {
        part_number
      }
      surah {
        surah_number
        name {
          text
        }
      }
    }
    site {
      siteMetadata {
        quranSiteUrl
        siteUrl
      }
    }
    allStrapiLocale {
      nodes {
        original
        text {
          lang {
            lang
          }
          text
        }
      }
    }
    allStrapiLangs {
      nodes {
        lang
        lang_name
        flag {
          publicURL
        }
      }
    }
    allStrapiReaders {
      nodes {
        reader_name
        reader
        lang {
          lang
        }
        main_image {
          publicURL
        }
      }
    }
  }
`